<template lang="pug">
.listgames
  //- Listado de categorías
  GroupCategories(:categories="categories")
  
  //- Listado de todos los juegos
  v-container
    h2.listgames__title {{ translations.title }}
    .listgames__group
      .listgames__item(
        v-for="game in gamesToShow"
        :key="game.id")
        CardGame(
          :title="game.name",
          :subtitle="game.type",
          :image="game.image"
          :gameId="game.vg"
          :fun="game.fun")
    div(v-intersect="onIntersect")
</template>

<script>
import { mapState } from "vuex"
import CardGame from "@/components/CardGame"

import GamesServices from "@/services/GamesServices"
import GroupCategories from "@/components/GroupCategories"

export default {
  name: "AllGames",
  components: {
    CardGame,
    GroupCategories,
  },
  data: () => ({
    games: [],
    items: 1,
    itemsPerPage: 20,
    showing: false,
  }),
  computed: {
    ...mapState(['lobbyId', 'categories', 'token']),
    translations() {
      return this.$t('views.AllGames')
    },
    gamesToShow() {
      return this.games.slice(0, this.items)
    }
  },
  created() {
    GamesServices.getAllGames(this.lobbyId).then(games => {
      this.games = [...games]
    })
  },
  methods: {
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.items += this.itemsPerPage
      }
    },
  },
};
</script>

<style lang="sass">
</style>

import axios from 'axios'
import Normalize from './normalize'

export default {
  async getAllGames(lobbyId) {
    return axios.get(`${process.env.VUE_APP_SERVER}/lobby/${lobbyId}/allgames`)
      .then((res) => {
        return Normalize.allgames(res.data)
      })
      .catch((error) => {
        console.log(error)
      });
  },
  async getGamesByProvider(lobbyId, providerId) {
    return axios.get(`${process.env.VUE_APP_SERVER}/lobby/${lobbyId}/provider/${providerId}/games`)
      .then((res) => {
        return Normalize.allgames(res.data)
      })
      .catch((error) => {
        console.log(error)
      });
  },
}